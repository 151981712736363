// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC46XLxUHHsVMsPyoUVSBi2aKn0gnOYNm4",
    authDomain: "serendibytes-chat.firebaseapp.com",
    projectId: "serendibytes-chat",
    storageBucket: "serendibytes-chat.firebasestorage.app",
    messagingSenderId: "795298738368",
    appId: "1:795298738368:web:0edf6c01de460832683d25",
    measurementId: "G-ZDJSN3P8DG"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, GoogleAuthProvider, signInWithPopup, storage };
