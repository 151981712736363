import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile
} from "firebase/auth";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./App.css";

const App = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [newPfpUrl, setNewPfpUrl] = useState("");
  const [isPfpModalOpen, setIsPfpModalOpen] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  
  const messagesEndRef = useRef(null);

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notifications.");
      return;
    }
  
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
    } else {
      alert("Notification permission denied.");
    }
  };

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Fetch chat messages
  useEffect(() => {
    let lastNotificationTime = 0;

    const showNotification = (message) => {
      const currentTime = Date.now();
  
      // Check if 30 seconds have passed since the last notification
      if (currentTime - lastNotificationTime < 30000) {
        console.log("Skipping notification; sent too recently.");
        return;
      }
  
      // Update the last notification time
      lastNotificationTime = currentTime;
  
      if (Notification.permission === "granted") {
        new Notification("New Message", {
          body: message.text || "Check SerendiBytes Chat!",
          icon: message.photoURL || "https://i.imgur.com/stc1Pa7.png", // Optional: Message sender's profile picture
        });
      }
    };
  
    const messagesRef = collection(db, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docChanges()
      newMessages.forEach((message) => {
        // Show notification for each new message
        showNotification(message);
      });
      setMessages(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    return () => unsubscribe();
  }, []);

  // Scroll to the bottom of the chat whenever messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Handle registration
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, { displayName: name, photoURL });
      alert("Account created successfully. You can now log in.");
      setEmail("");
      setPassword("");
      setName("");
      setPhotoURL("");
      setIsRegistering(false);
    } catch (error) {
      console.error("Error during registration:", error);
      alert(error.message);
    }
  };

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setEmail("");
      setPassword("");
    } catch (error) {
      console.error("Error during login:", error);
      alert(error.message);
    }
  };

  // Handle logout
  const handleLogout = () => {
    signOut(auth);
    setUser(null);
  };

  // Handle sending a message
  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" && !imageFile) return;

    let imageUrl = null;

    // Upload image to Firebase Storage if selected
    if (imageFile) {
      const imageRef = ref(storage, `images/${imageFile.name}-${Date.now()}`);
      const uploadResult = await uploadBytes(imageRef, imageFile);
      imageUrl = await getDownloadURL(uploadResult.ref);
    }

    const messagesRef = collection(db, "messages");
    await addDoc(messagesRef, {
      text: newMessage,
      uid: user.uid,
      displayName: user.displayName || "Anonymous",
      photoURL: user.photoURL || "",
      timestamp: serverTimestamp(),
      replyTo: replyingTo ? { id: replyingTo.id, text: replyingTo.text } : null,
      imageUrl, // Add the image URL if exists
    });

    setNewMessage("");
    setReplyingTo(null);
    setImageFile(null); // Clear the selected image
  };

  const handleReply = (message) => {
    setReplyingTo(message);
  };

  // const handleImageChange = (e) => {
  //   setImageFile(e.target.files[0]);
  // };

  // Handle profile picture update
  const updateProfilePicture = async (e) => {
    e.preventDefault();
    if (!newPfpUrl.trim()) {
      alert("Please enter a valid image URL.");
      return;
    }

    try {
      await updateProfile(user, { photoURL: newPfpUrl });
      alert("Profile picture updated successfully.");
      setNewPfpUrl(""); // Clear the input field
      setIsPfpModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error updating profile picture:", error);
      alert("Failed to update profile picture.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent default Enter behavior (creating a new line in the textarea)
      e.preventDefault();
      sendMessage(e); // Call the sendMessage function
    }
  };

  return (
    <div className="app">
      <img alt="" className="logo" src="https://serendibytes-web.web.app/assets/logo-long.9f21bac7.png"></img>
      {/* <img alt="" className="logo" src="https://i.imgur.com/stc1Pa7.png"></img> */}
      {user ? (
        <div className="chat-container">
        <header className="chat-header">
          <div className="profile" onClick={() => setIsPfpModalOpen(true)}>
            <img
              src={user.photoURL || "https://via.placeholder.com/40"}
              alt={`${user.displayName}'s profile`}
              className="profile-pic"
            />
            <h2>{user.displayName || "User"}</h2>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            <img alt="" src="https://www.freeiconspng.com/thumbs/power-button-icon/red-power-button-icon-24.png" className="logout-icon"/>
          </button>
        </header>

        <div className="chat-messages">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`message ${
                msg.uid === user.uid ? "message-sent" : "message-received"
              }`}
            >
              <img
                src={msg.photoURL || "https://via.placeholder.com/40"}
                alt={`${msg.displayName}'s profile`}
                className="message-profile-pic"
              />
              <div className="message-content">
                <div className="message-info">
                  <span className="message-sender">{msg.displayName}</span>
                  &nbsp;&nbsp;
                  <span className="message-timestamp">
                    {msg.timestamp?.toDate().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                {msg.replyTo && (
                  <div className="message-reply">
                    <i>| {msg.replyTo.text}<br></br></i>
                  </div>
                )}
                <div className="message-text">
                {msg.text.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                </div>
                {msg.imageUrl && (
                  <img
                    src={msg.imageUrl}
                    alt="Attached"
                    className="message-image"
                  />
                )}
              </div>
              <button
                className="reply-button"
                onClick={() => handleReply(msg)}
              >
                <img className="reply-image" src="https://cdn2.iconfinder.com/data/icons/web-basics-vol-02/512/arrow_back_message_previous_reply_respond_response-512.png" alt=""></img>
              </button>
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>

        <form className="chat-input" onSubmit={sendMessage}>
          {replyingTo && (
            <div className="reply-preview">
              <button
                type="button"
                className="cancel-reply"
                onClick={() => setReplyingTo(null)}
              >
                ❌
              </button>&nbsp;
              <b>Replying to:</b><br /> {replyingTo.text}
            </div>
          )}
          <textarea
            className="message-input"
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button className="send" type="submit">Send</button>
        </form>
      </div>
      ) : (
        <div className="auth-container">
          {isRegistering ? (
            <form className="auth-form" onSubmit={handleRegister}>
              <h2>Register</h2>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="text"
                placeholder="Profile Picture URL"
                value={photoURL}
                onChange={(e) => setPhotoURL(e.target.value)}
              />
              <button type="submit">Register</button>
              <p>
                Already have an account?{" "}
                <button type="button" onClick={() => setIsRegistering(false)}>
                  Log In
                </button>
              </p>
            </form>
          ) : (
            <form className="auth-form" onSubmit={handleLogin}>
              <h2>Log In</h2>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit">Log In</button>
              {/* <p>
                Don’t have an account?{" "}
                <button type="button" onClick={() => setIsRegistering(true)}>
                  Register
                </button>
              </p> */}
            </form>
          )}
        </div>
      )}

      {/* Profile Picture Modal */}
      {isPfpModalOpen && (
        <div className="modal-overlay" onClick={() => setIsPfpModalOpen(false)}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <h3>Update Profile Picture</h3>
            <form onSubmit={updateProfilePicture}>
              <input
                type="text"
                placeholder="Enter new profile picture URL"
                value={newPfpUrl}
                onChange={(e) => setNewPfpUrl(e.target.value)}
              />
              <button type="submit">Update</button> &nbsp;&nbsp;
              <button
                className="modal-close-button"
                onClick={() => setIsPfpModalOpen(false)}
              >
              Close
            </button>
            </form>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
